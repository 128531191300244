<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
    size="sm"
    textSave="hidden"
  >
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col md="12">
            <b-card align="center">
              <!-- img -->
              <b-img
                v-for="(img, index) in package_datas.image_datas"
                :key="index"
                :src="img.image_url"
                class="mb-2 mt-5"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ package_datas.name }}</h3>
              <!-- <b-card-text> {{ xx }} </b-card-text> -->

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"
                    >$</sup
                  >
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                    >{{ withCommas(package_datas.amount) }}</span
                  >
                  <!-- <sub
                    class="
                      pricing-duration
                      text-body
                      font-medium-1 font-weight-bold
                    "
                    >/month</sub
                  > -->
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item>
                  {{ package_datas.detail }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->
            </b-card>
          </b-col>

          <pay-pal
            @paypal-callback="handleSubmit"
            :totalPrice="package_datas.price"
            :description="package_datas.name"
          />
        </b-row>
      </b-col>
    </b-row>
  </form-modal>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: "",
        account_no: "",
        price: "",
        package_data_id: "",
        receive_price: "",
        files: [],
      },
      package_datas: "",
    }
  },
  computed: {
    _title() {
      return `Buy ${this.package_datas?.name ?? ""}`
    },
  },
  methods: {
    show(data) {
      this.clearForm()
      this.switch("show")
      this.form.package_data_id = this.cp(data.id)
      this.package_datas = data
    },
    async handleSubmit(paypal) {
      try {
        const form = this.cp(this.package_datas)
        form.paypal = paypal

        await this.api.post("/api/packageCheckout/checkout", form)
        this.alert.success({ text: "" })
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
